import React, { useEffect, useState } from "react";
import { oauth, post } from "./components/api";
import Form from "./components/Form";
import Label from "./components/Label";
import "./css/App.css";

export const AppContext = React.createContext();

function App() {
	const queryParams = new URLSearchParams(window.location.search);
	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState(false);
	const [labels, setLabels] = useState(false);
	const [error, setError] = useState(false);
	const [vote, setVote] = useState(false);
	const [voted, setVoted] = useState(false);
	const [reservation, setReservation] = useState(false);
	const [order, setOrder] = useState(false);
	const [shop, setShop] = useState(false);
	const [mode, setMode] = useState(queryParams.get("order_id") ? "order" : "reservation");

	useEffect(() => {
		init();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (reservation) {
			if (queryParams.get("vote") && !reservation.review) {
				setVote(queryParams.get("vote"));
			}
		}
		// eslint-disable-next-line
	}, [reservation]);

	useEffect(() => {
		if (order) {
			if (queryParams.get("vote") && !order.review) {
				setVote(queryParams.get("vote"));
			}
		}
		// eslint-disable-next-line
	}, [order]);

	async function init() {
		console.log("init");

		await oauth();
		await loadSettings();
		await loadLabels();

		const orderId = queryParams.get("order_id");
		const reservationId = queryParams.get("reservation_id");
		if (orderId) await loadOrder();
		if (reservationId) await loadReservation();

		setLoading(false);
	}

	async function loadSettings() {
		console.log("loadSettings");
		const response = await post("/settings2/get/", {
			section: ["global", "website"],
		});

		setSettings(response.data);

		document.title = "Reviews | " + response.data.global.name;
		var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "shortcut icon";
		link.href = response.data.global.favicon;
		document.getElementsByTagName("head")[0].appendChild(link);

		return response.data;
	}

	async function loadLabels() {
		console.log("loadLabels");
		const response = await post("/labels/list/", {
			type: "reviews",
		});
		setLabels(response.rows);
	}

	async function loadReservation() {
		console.log("loadReservation");
		let reservationId = queryParams.get("reservation_id");
		if (reservationId) {
			const response = await post("/reservations/get/" + reservationId + "/");
			loadShop(response.data.shop_id);
			setReservation(response.data);
			console.log("review", response.data.review);
			if (response.data.review) setError(<Label number={8} />);
		} else {
			setError("Prenotazione non trovata");
		}
	}

	async function loadOrder() {
		console.log("loadOrder");
		let orderId = queryParams.get("order_id");
		if (orderId) {
			const response = await post("/orders/get/" + orderId + "/");
			loadShop(response.data.shop_id);
			setOrder(response.data);
			console.log("review", response.data.review);
			if (response.data.review) setError(<Label number={12} />);
		} else {
			setError("Ordine non trovato");
		}
	}

	async function loadShop(shopId) {
		console.log("loadShop");
		const response = await post("/shops/get/" + shopId + "/");
		setShop(response.data);
	}

	const handleVote = async (value) => {
		console.log("handleVote");
		if (value > 1) {
			if (mode == "reservation") await editReservation(value);
			if (mode == "order") await editOrder(value);
		} else setVote(value);
	};

	const editReservation = async (value) => {
		console.log("editReservation");
		const response = await post("/reservations/edit/" + reservation.id + "/", {
			review: value,
		});
		if (response.success == 0) alert(response.error);
		else setVote(value);
	};

	const editOrder = async (value) => {
		console.log("editOrder");
		const response = await post("/orders/edit/" + order.id + "/", {
			review: value,
		});
		if (response.success == 0) alert(response.error);
		else setVote(value);
	};

	return (
		<AppContext.Provider
			value={{
				labels,
				reservation,
				order,
				vote,
				setVote,
				setVoted,
				mode,
			}}
		>
			{settings && (
				<div className="container">
					<div className="header" style={{ backgroundColor: settings.global.color1 }}>
						<img src={settings.global.logo} alt={settings.global.name} />
					</div>
					{!error && !voted && (
						<div className="content">
							{!vote ? (
								<>
									<div className="text">
										<Label number={1} />
									</div>
									<div className="buttons">
										<button onClick={() => handleVote(4)}>
											<span className="icon">&#128515;</span>
											<Label number={2} />
										</button>
										<button onClick={() => handleVote(3)}>
											<span className="icon">&#128578;</span>
											<Label number={3} />
										</button>
										<button onClick={() => handleVote(2)}>
											<span className="icon">&#128528;</span>
											<Label number={4} />
										</button>
										<button onClick={() => handleVote(1)}>
											<span className="icon">&#128577;</span>
											<Label number={5} />
										</button>
									</div>
								</>
							) : (
								<>
									{vote == 1 && (
										<>
											<div className="text">
												<Label number={6} />
											</div>
											<Form />
										</>
									)}
									{vote > 1 && (
										<>
											<div className="text">
												<Label number={9} />
												<br></br>
												<Label number={11} />
											</div>
											{shop.reservations_reviews_embed_form && (
												<div
													dangerouslySetInnerHTML={{
														__html: shop.reservations_reviews_embed_form,
													}}
												></div>
											)}
										</>
									)}
								</>
							)}
						</div>
					)}
					{voted && (
						<div className="content">
							<Label number={9} />
						</div>
					)}
					{error && <div className="content">{error}</div>}
				</div>
			)}
			{loading && <div className="loading">Loading...</div>}
		</AppContext.Provider>
	);
}

export default App;
